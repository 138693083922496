
import * as uploadcareRuntime$LW9bMv93QK from '/app/node_modules/.pnpm/@nuxt+image@1.1.0_@planetscale+database@1.19.0_db0@0.3.1_drizzle-orm@0.36.4_@planetscal_3ed921c007ae3c005c808616da3e46b7/node_modules/@nuxt/image/dist/runtime/providers/uploadcare'
import * as directusRuntime$dwz8m0yPYI from '/app/node_modules/.pnpm/@nuxt+image@1.1.0_@planetscale+database@1.19.0_db0@0.3.1_drizzle-orm@0.36.4_@planetscal_3ed921c007ae3c005c808616da3e46b7/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as ipxRuntime$bxujuFElS7 from '/app/node_modules/.pnpm/@nuxt+image@1.1.0_@planetscale+database@1.19.0_db0@0.3.1_drizzle-orm@0.36.4_@planetscal_3ed921c007ae3c005c808616da3e46b7/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['uploadcare']: { provider: uploadcareRuntime$LW9bMv93QK, defaults: {"cdnURL":"https://ucarecdn.com/"} },
  ['directus']: { provider: directusRuntime$dwz8m0yPYI, defaults: {"baseURL":"https://directus-production-mhgotunjaq-an.a.run.app/assets/"} },
  ['ipx']: { provider: ipxRuntime$bxujuFElS7, defaults: {"maxAge":31536000} }
}
        